import React, {useEffect, useState} from "react";
import styles from "./mainLayout.module.css";
import Sidebar from "../../components/layout/sidebar";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import InvitationHandler from "../../components/utility/invitationHandler";
import {useAppContext} from "../../context/app-context";

const MainLayout = () => {
  const { getSelectedTenant } = useAppContext();
  const [pollInvitations, setPollInvitations] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    const elem = document.getElementById(`main-content`);
    elem?.scroll({ top: 0, left: 0 });
  }, [pathname]);

  useEffect(() => {
    setPollInvitations(true);
  }, []);

  return (
      <div className={styles.layoutContainer}>
        <div className={styles.layoutGroup}>
          <Sidebar />
          <div id={"main-content"} className={styles.content}>
            {getSelectedTenant ? <Outlet /> : null}
            <InvitationHandler startPolling={pollInvitations}/>
          </div>
        </div>
      </div>
  );
};

export default MainLayout;
