import { TFunction } from "i18next";

export interface IErrorResponse {
  statusCode: number;
  message: string;
  bridgeErrorCode?: BridgeErrorCode;
}

export interface ICheckInResponse {
  id: string;
  createdAt: string;
  origin: string;
}

export interface IBuySubscriptionRequest {
  stripePriceId?: string;
}

export interface IBuySubscriptionResponse {
  stripeCheckoutUrl: string;
}

export interface IBridgeCredentials {
  name: string;
  idToken: string;
  expiresOn: Date | null;
}

export interface IPostTenantRequest {
  displayName: string;
  scenarios?: string[];
  associatedDomains?: string[];
  defaultUserRole?: UserRoles;
}

export interface IUpdateTenantRequest {
  id: string;
  courseIds?: string[];
  supportAccess?: boolean;
}

export interface ISupportUpdateTenantRequest {
  id: string;
  displayName?: string;
  associatedDomains?: string[];
  tokenDuration?: TokenDuration;
}

export interface IInviteUserRequest {
  email: string;
  role: UserRoles;
  tenantId?: string;
}

export interface IDeleteUserRoleRequest {
  id: string;
}

export interface IUpdateUserRequest {
  id: string;
}

export interface IUpdateUserRoleRequest {
  id: string;
  role: string;
}

export interface IBuySubscriptionResponse {
  stripeCheckoutUrl: string;
}

export interface UpdateUserProfileRequest {
  fullName?: string;
  completedFirstTimeSetup?: boolean;
}

export interface UserProfileResponse {
  id: string;
  fullName: string;
  completedFirstTimeSetup: boolean;
  email: string;
  isSupport: boolean;
  tenantRoles: { [key: string]: UserRoles };
  tenantInvites: TenantInvite[];
}

export interface TenantInvite {
  id: string;
  tenantId: string;
  role: UserRoles;
  createdAt: Date;
}

// Data model on user
export interface TenantInvite {
  id: string;
  tenantId: string;
  role: UserRoles;
}

// DTO for tenant invite, GetInvites endpoint.
export interface UserTenantInviteResponse {
  id: string;
  tenantId: string;
  tenantName: string;
  invitedByEmail?: string;
}

export interface TokensAssignmentRequest {
  assignedToUserId: string;
}

export interface AssignTokensRequest {
  courseId: string;
  tokenAssignments: TokensAssignmentRequest[];
}

export interface UpdateTokenRequest {
  id: string;
  autoRenew: boolean;
}

export interface AssignTokensResponse {
  assigment: TokenAssignment;
}

export interface TokenAssignment {
  id: string;
  assignedToUserId: string;
  assignedToUser?: User;
  autoRenew: boolean;
  courseId: string;
  expiryDate: string;
  isActive: boolean;
  createdAt: string;
}

export interface TenantLicense {
  id: string;
  name: string;
  expiryDate: string;
  stripeSubscriptionId: string;
  autoRenew: boolean;
  status: LicenseStatus;
  tokenAssignments: TokenAssignment[];
  tokenCount: number;
  usedTokenCount: number;
  reference?: string;
}

export enum LicenseStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export interface UpdateLicenseRequest {
  id: string;
  shouldRenew: boolean;
  name: string;
}

export interface CustomerResponse {
  email: string;
  defaultPaymentMethod: PaymentMethodInfo | undefined;
  subscriptionsDefaultPaymentMethod: {
    [key: string]: PaymentMethodInfo | undefined;
  };
}

export interface PaymentMethodInfo {
  last4: string | undefined;
  paymentMethodType: string | undefined;
}

export interface CustomerPortalResponse {
  url: string;
}

export enum TokenDuration {
  Month = 0,
  Year = 1,
}

export interface Tenant {
  id: string;
  displayName: string;
  associatedDomains: string[];
  licenses: TenantLicense[];
  courseIds: string[];
  supportAccess: string;
  tokenDuration: TokenDuration;
}

export interface TenantInvite {
  id: string;
  tenantId: string;
  role: UserRoles;
}

export interface User {
  id: string;
  email: string;
  fullName?: string;
  lastSeen?: string;
  createdAt: string;
  userTenantRoles: UserTenantRole[];
  tenantInvites: TenantInvite[];
  isSupport: boolean;
}

export interface UserTenantRole {
  id: string;
  tenantId: string;
  userId: string;
  role: UserRoles;
}

export interface IGetCoursesResponse {
  items: Course[];
}

export interface Course {
  id: string;
  name: string;
  courseId: string;
  bundledCourses: string[];
  licenseCost: number;
}

export interface IPostLicense {
  name: string;
  tokenCount: number;
  expiryDate: Date;
  autoRenew: boolean;
  reference: string;
}

export interface IUpdateLicense extends IPostLicense {
  licenseId: string;
  usedTokenCount: number;
  status: LicenseStatus;
}

export interface IUpdateTokenAssignmentRequest {
  id: string;
  expiryDate: Date;
  autoRenew: boolean;
  isActive: boolean;
}

export enum FileSharingOptions {
  Everyone = 0,
  Private = 1,
  Instructor = 2,
}

export interface UserFileUploadRequest {
  displayName: string;
  contentType: string;
  sizeKilobytes: number;
  scenarioIds?: string[];
  sharingOption: FileSharingOptions;
}

export interface UpdateUserFileRequest {
  id: string;
  displayName: string;
  sharingOption: FileSharingOptions;
}

export interface UserFile {
  id: string;
  scenarioIds: string[];
  roomContainerId?: string;
  roomContainer?: RoomContainer;
  displayName: string;
  contentType: string;
  sizeKilobytes: number;
  transferUrl?: string;
  isReadyForTransfer: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy?: string;
  createdByUser?: User;
  sharingOption: FileSharingOptions;
}

export interface IUserFilesRequestOptions extends IPagingOptions {
  loadUsers?: boolean;
  loadRoomContainers?: boolean;
  scenarioId?: string;
  excludeScenarioFiles?: boolean;
  excludeRoomContainerFiles?: boolean;
  excludeOtherPrivateFiles?: boolean;
  scenarioOnly?: boolean;
  roomContainerOnly?: boolean;
}

export interface IUserFileRequestOptions {
  preview?: boolean;
}

export interface RoomContainer {
  id: string;
  roomName: string;
}

export interface Scenario {
  id: string;
  scenarioName: string;
  tags: { [key: string]: string };
}

export interface CourseListAnalyticsViewModel {
  courseId: string;
  numberOfSessions: number;
  averageSessionLength: number;
}

export interface SessionListAnalyticsViewModel {
  userId: string;
  sessionStart: string;
  email?: string;
  username?: string;
  role?: UserRoles;
  sessionName?: string;
  duration: number;
  courseId: string;
}

export interface SessionSummaryAnalyticsViewModel {
  totalTime: number;
}

export interface AnalyticsRequestOptions {
  from?: string;
  to?: string;
}

export interface ISessionListAnalyticsViewRequestOptions
  extends IPagingOptions,
    AnalyticsRequestOptions {
  courseId?: string;
  userId?: string;
}

export interface SessionSummaryRequestOptions extends AnalyticsRequestOptions {
  courseId?: string;
  userId?: string;
}

export interface IStorageState {
  total: number;
  used: number;
  maxFileSize: number;
}

export interface IPaginatedResponse<T> {
  items: T[];
  continueToken?: string;
  count: number;
  totalCount: number;
}

export interface IPagingOptions {
  continueToken?: string;
  limit?: number;
  ids?: string[];
  search?: string;
  sort?: string;
}

export interface ITokensRequestOptions extends IPagingOptions {
  all?: boolean;
  courseId?: string;
  loadUsers?: boolean;
  includeInactive?: boolean;
}

export enum UserRoles {
  Guest = "Guest", // 100
  RegularUser = "RegularUser", // 500
  Administrator = "Administrator", // 1000
}

export function GetUserRoles(t: TFunction<"translation">) {
  return [
    { name: t("usersPage.roles.admin"), value: UserRoles.Administrator },
    { name: t("usersPage.roles.instructor"), value: UserRoles.RegularUser },
    { name: t("usersPage.roles.trainee"), value: UserRoles.Guest },
  ];
}

export function GetUserRole(
  userRole: UserRoles | undefined,
  t: TFunction<"translation">
): string | undefined {
  return GetUserRoles(t).find((r) => r.value == userRole)?.name;
}

export enum BridgeErrorCode {
  ServerError = "bridge-001-servererror",
  NotFound = "bridge-002-notfound",
  InvalidQuery = "bridge-003-invalidquery",
  InvalidBody = "bridge-004-invalidbody",
  InvalidHeader = "bridge-005-invalidheader",
  NotImplemented = "bridge-006-notimplemented",
  ClientError = "bridge-007-clienterror",
  BusinessLogicError = "bridge-008-businesslogicerror",
  InsufficientUserRole = "bridge-009-insufficientuserrole",
  UserNotRegistered = "bridge-010-usernotregistered",
  PaymentError = "bridge-011-paymenterror",
  TenantNameNotUnique = "bridge-012-tenantnamenotunique",
}

export enum UserFileSortingKey {
  DisplayName = "displayName",
  ContentType = "contentType",
  SharingOption = "sharingOption",
  Size = "size",
  CreatedAt = "createdAt",
  UploadedBy = "createdBy",
  RoomContainer = "roomContainer",
}

export enum UserSortingKey {
  Email = "email",
  UserName = "fullName",
  Role = "role",
  Status = "status",
  UserCreated = "createdAt",
  LastActive = "lastActive",
}

export enum TokenSortingKey {
  Email = "email",
  UserName = "fullName",
  Role = "role",
  Status = "status",
  Expiration = "expiration",
  AutoRenewal = "autoRenewal",
}
