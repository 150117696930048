import React, { useEffect, useState } from "react";
import styles from "./mobilePage.module.css";
import { ReactComponent as KandaLogo } from "../assets/img/kanda-logo-sidebar.svg";
import { ReactComponent as PersonIcon } from "../assets/navigation/person.svg";
import { ReactComponent as KeyIcon } from "../assets/navigation/key.svg";
import { ReactComponent as LogoutIcon } from "../assets/navigation/logout.svg";
import { useAuth0 } from "@auth0/auth0-react";
import VTPStyles from "../styles/vtpStyles";
import VTPButton, { ButtonSize, ButtonType } from "../components/base/button";
import AuthUtilities from "../lib/authUtilities";
import InvitationHandler from "../components/utility/invitationHandler";
import { useAppContext } from "../context/app-context";

const MobilePage = () => {
  const { logout } = useAuth0();
  const { getUserProfile } = useAppContext();
  const [pollInvitations, setPollInvitations] = useState(false);
  useEffect(() => {
    setPollInvitations(true);
  }, []);

  return (
    <div className={`${styles.container}`}>
      <InvitationHandler
        startPolling={pollInvitations}
        className={styles.invitationNotification}
      />
      <span className={styles.kandaLogo}>
        <KandaLogo />
      </span>
      <div className={styles.userInfo}>
        <PersonIcon />
        <span className={`${VTPStyles.Typography.Buttons.SizeSmall}`}>
          {getUserProfile?.fullName}
        </span>
      </div>
      <div className={styles.inputCodeWrapper}>
        <div className={styles.inputCodeSection}>
          <VTPButton
            className={styles.inputCodeButton}
            size={ButtonSize.Large}
            type={ButtonType.Primary}
            onClick={() => AuthUtilities.StartDeviceCodeInput()}
          >
            <KeyIcon />
            Input code
          </VTPButton>
          <div className={VTPStyles.Typography.Body.Medium}>
            Login on a desktop to access more features.
          </div>
        </div>
      </div>
      <div className={styles.logoutButton}>
        <VTPButton
          size={ButtonSize.Small}
          type={ButtonType.Primary}
          onClick={() => logout()}
        >
          <LogoutIcon />
          Log out
        </VTPButton>
      </div>
    </div>
  );
};

export default MobilePage;
